<template>
  	<div id="unitGallery">

		<div id="instant_unit_search" class="row" style="margin-bottom: 15px">
			<div class="col-xs-1 col-md-3 col-lg-3"></div>
			<div class="col-xs-10 col-md-6 col-lg-6">
				
			</div>
			<div class="col-xs-1 col-md-3 col-lg-3"></div>
		</div>

		<div class="row" style="margin-bottom:20px">
			<div class="col-sm-3">
				<div style="font-size: 28px; font-weight:bold">Featured Topics</div>
			</div>
			<div class="col-sm-6">
				<div class="input-group">
					<!--<span class="input-group-addon" id="basic-addon1"><span class="glyphicon glyphicon-search" aria-hidden="true"></span></span>					-->
					<input type="text" class="form-control" v-model="quiz_unit_search" placeholder="Search all topics">
					<!--<span class="input-group-btn">
						<button class="btn btn-default" type="button">Search!</button>
					</span>-->
				</div>
			</div>
			<div class="col-sm-3" style="text-align: right; font-weight: bold">
				<a href="#!/scorecard" class="btn btn-primary">View All Topics &raquo;</a>
			</div>
		</div>
		
		<div id="unitGallery" class="row">
			<div class="col-md-3" v-for="unit in units_filtered" :key="unit.code">
				<UnitCard :unit="unit" />
			</div>
		</div>

		<div style="text-align: right">
			<a href="#!/scorecard" class="btn btn-primary">View All Topics &raquo;</a>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import UnitCard from './UnitCard.vue'	
	import {AppState, makeComparer} from "../main"

    export default {
        data() {
            return {
                quiz_unit_search: "",
                units: AppState.unitCache				
            }        
		},
		components: {UnitCard},
        computed: {
            units_filtered() {
				let quiz_unit_search = this.quiz_unit_search.toUpperCase();                
				let showHidden = window.location.toString().toLowerCase().indexOf("showhidden") >= 0
				let units = this.units.slice().sort(() => Math.random() < 0.5 ? -1 : 1)
				if (!showHidden) units = units.filter(x => !x.hidden)
				if (quiz_unit_search) {
					units = units.filter(x => x.title.toUpperCase().indexOf(quiz_unit_search) > -1)
				} else {
					const featuredUnitsKeywords = "Elul|Rosh|Teshuva|Kippur|Sukkos|HaMoed|Davening Alone|Me'ah".split("|")
					const whichMatches = unitTitle => featuredUnitsKeywords.indexOf(featuredUnitsKeywords.find(kw => unitTitle.includes(kw)))
					const comparer = makeComparer(u => (whichMatches(u.title)+1) || 1000) //unit titles that didn't match a featured keyword have -1, so change that to 1000 so they're last
					units.sort(comparer)
				}
				if (!showHidden) units = units.slice(0, 16)
				return units
            }
        }    
    }
</script>