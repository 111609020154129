
















































import Component from "vue-class-component";
import Vue from "vue";    
import {AppState, makeComparer, Grouping, trimGroupFromTitle} from "../main"
import ScorecardRow from "./Scorecard_Row.vue"
import UnitCard from "./UnitCard.vue"
import { HUnit } from "../UnitConverter";

@Component({        
    components: {ScorecardRow, UnitCard}
})
export default class Scorecard extends Vue {
    showHidden = window.location.toString().toLowerCase().indexOf("showhidden") >= 0        
    viewBy = "area"
    cardView = true
    get units() {
        return AppState.unitCache
        .filter(x=>this.showHidden || !x.hidden)
        //.slice().sort(makeComparer(x=>x.title))//(a,b)=>a.title > b.title ? 1 : a.title < b.title ? -1 : 0)
    }
    get unitsGrouped() {
        const x = this.units
        const findColon = (txt:string) => txt.indexOf(":")
        const getGroup = (txt:string) => findColon(txt)>=0 ? txt.split(":")[0] : "Misc."
        return Grouping.create(x, u=>getGroup(u.title)).sortGroups(g=>g.key).groups
    } 

    getLastScore(unit:any) {
        return unit.lastScore || unit.bestScore
    }
    passed(unit:any) {
        return this.getLastScore(unit) && (this.getLastScore(unit).stars >= 3)
    }                        
    splitTitle(u:HUnit) {
        return trimGroupFromTitle(u.title)
    }
}
