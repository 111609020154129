import Vue from 'vue'
import App from './App.vue'

import { HUnit } from "./UnitConverter"
import { compiledUnits } from "./compiledUnits"
compiledUnits.forEach(u=>{
  u.viewUrl = `#!/unit/${u.code}`
  u.quizUrl = `#!/quiz/${u.code}`
})
console.info(`Loaded ${compiledUnits.length} units`)

export const AppState = {
  unitCache: (compiledUnits as HUnit[])
}

export function localStorageSetting<T>(key: string, defaultValue: T) {
  const key2 = `_localStorageSetting_${key}`  
  const get = () => {
    const v = localStorage.getItem(key2)
    return (v === null) ? defaultValue : JSON.parse(v)
  }
  const set = (newValue: T) => localStorage.setItem(key2, JSON.stringify(newValue))
  const modify = (fn: (curValue: T)=>T) => set(fn(get()))
  return { get, set, modify }
}

export class QuizScore {
  constructor(public rightAnswers: number, public totalQuestions: number) {
  }
  get percent() { return Math.round(this.rightAnswers / this.totalQuestions * 100) }
  get stars() { 
    if (this.percent>=95) return 3
    if (this.percent>=80) return 2
    if (this.percent>=85) return 1
    return 0
  }
}
export interface UnitProgress {
  slideIndex: number
  date: number
}
const settings = {
  progressFor: (unit: HUnit) => localStorageSetting<UnitProgress|null>("progress_" + unit.code, null),
  scoresFor: (unit: HUnit) => localStorageSetting<QuizScore[]>("scores_" + unit.code, []),
}

for (const u of compiledUnits) {
  const progress = settings.progressFor(u).get()
  if (progress) { u.inProgress = true; (u as any).progress = progress }
}

export function makeComparer<TItem, TKey>(keyFunc: (item: TItem) => TKey) {
  return (a: TItem, b: TItem) => {
    const keyA = keyFunc(a), keyB = keyFunc(b)
    return (keyA > keyB) ? 1 : (keyA < keyB) ? -1 : 0
  }
}

export class Grouping<TItem> {
  groupMap: { [s: string]: Group<TItem> } = {}
  groups: Group<TItem>[] = []
  allItems: TItem[] = []
  add(item: TItem, key: string) {
    if (!this.groupMap[key]) {
      const newGroup: Group<TItem> = { key: key, items: [], _isGroup: true, display: key }
      this.groupMap[key] = newGroup
      this.groups.push(newGroup)
    }
    this.groupMap[key].items.push(item)
    this.allItems.push(item)
  }
  static create<TItem>(items: TItem[], keyFunc: (item: TItem) => string) {
    const ret = new Grouping<TItem>()
    for (const i of items) ret.add(i, keyFunc(i))
    return ret
  }
  flatten() {
    const ret: (TItem | Group<TItem>)[] = []
    for (const grp of this.groups) {
      ret.push(grp)
      for (const i of grp.items) ret.push(i)
    }
    return ret
  }
  setDisplay(displayFunc: (group: Group<TItem>) => string): this {
    for (const grp of this.groups)
      grp.display = displayFunc(grp)
    return this
  }
  sortGroups(sortKey?: (group: Group<TItem>) => any): this {
    this.groups = this.groups.slice().sort(makeComparer(sortKey || (x => x.key)))
    return this
  }
}

export function trimGroupFromTitle(text: string) {
  const pos = text.indexOf(":")
  return pos < 0 ? text : text.substr(pos + 1).trim()
}
export function debounce(func: Function, wait: number, immediate?: boolean) {
  var timeout: NodeJS.Timer | null;
  return function (this: any): void {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export interface Group<T> {
  key: string
  items: T[]
  display: string
  _isGroup: true
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
