










































































import Component from "vue-class-component";
import Vue from "vue";
import { HUnit } from "../UnitConverter";
import { Grouping, AppState, makeComparer } from "../main";

@Component({
    props: {
    }
})
export default class Menahel extends Vue {
    get showProjectProgress_groups() {                
        const unitsWithProps = AppState.unitCache.map(this.unitWithProp).sort(makeComparer(i=>i.unit.title))        
        const grouped = Grouping.create(unitsWithProps, u=>u.groupName).groups
        return grouped
    }
    get unitsByStatus() {        
        // const ret = groupByKey(AppState.unitCache,u=>UnitStatus[GetUnitStatus(u)],g=>g.key.split("_")[2])            
        // const ret2 = ret.slice().sort(makeComparer(g=>g.key))            
        // return ret2
        return Grouping.create(AppState.unitCache,u=>UnitStatus[GetUnitStatus(u)])                
            .setDisplay(grp=>grp.key.split("_")[2])
            .sortGroups(grp=>grp.key)
    }
    getArea(colonGroups:string[]) {return colonGroups[1] ? colonGroups[0] : ""}
    getAreaFromUnit(unit:HUnit) {return this.getArea((unit.title || "").split(":")) }
    unitWithProp(unit:HUnit) {                
        const grpName = this.getAreaFromUnit(unit)                
        const titleInGroup = grpName ? unit.title.substr(grpName.length + 1).trim() : unit.title                
        const status = GetUnitStatus(unit)
        const ret = {unit:unit,groupName: grpName || "Misc.", titleInGroup: titleInGroup, status, statusText: UnitStatus[GetUnitStatus(unit)]}
        ;(<any>ret).statusColor = status>45 ? '#0A0' : status>35 ? '#990' : status>25 ? '#AA5' : status>15 ? '#55F' : '#444'
        ;(<any>ret).rowBg = status>85 ? '#BFB' : null
        ;(<any>ret).statusTextFriendly = /*status + ": " +*/ ret.statusText.split("_").slice(-1)[0]
        ;(<any>ret).lineCount = CountUnitLines(unit)
        return ret        
    }
}



export enum UnitStatus {
    _00_Nonexistent = 0,
    _10_Placeholder = 10,
    _20_Started = 20,
    _40_Lots = 40,
    _50_NeedCheck = 50,
    _80_StillHidden = 80,
    _90_Published = 90
}
export function GetUnitStatus(unit: HUnit): UnitStatus {
    if (!unit) return UnitStatus._00_Nonexistent
    if (unit.author == "None") return UnitStatus._00_Nonexistent
    if (!unit.hidden) return UnitStatus._90_Published
    if (unit.checkedOver) return UnitStatus._80_StillHidden
    if (unit.materialUrl) return UnitStatus._50_NeedCheck
    const totalLines = CountUnitLines(unit)
    if (totalLines > 70) return UnitStatus._40_Lots
    if (totalLines > 30) return UnitStatus._20_Started
    return UnitStatus._10_Placeholder
}
export function CountUnitLines(unit: HUnit) {
    const fnCountLines = (str: string) => str.split("\n").filter(x => x.trim()).length
    const slides = unit.slides || []
    const totalLines = slides.reduce((a, c) => a + fnCountLines(c.body), 0)
    return totalLines + slides.length
}

