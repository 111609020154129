























    	
	import { trimGroupFromTitle } from '../main';	
	import type { HUnit } from "../UnitConverter"
	import { Vue, Component, Prop } from 'vue-property-decorator';

	@Component({})
    export default class UnitCard extends Vue {
		@Prop({type: Object}) unit!: HUnit
		@Prop({type: Boolean}) trimTitle!: boolean

		get shownTitle() { return this.trimTitle ? trimGroupFromTitle(this.unit.title) : this.unit.title }
    }
