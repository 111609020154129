<template>  
  <div class="container" v-if="unit && quiz && quiz.questions.length">
        <!-- Score box -->
        <span :class="'bigCircle animated ' + (revealAnswers && chosenAnswer.correct ? 'rubberBand' : '')" style="position: fixed; right: 1em; z-index: 10; opacity: 0.93">
            <span style="display:inline-block; text-align:center">
                Score:<br/>
                <span style="font-size:25px; line-height:1em">{{curScore}}</span>
            </span>
        </span>
        <!-- Quiz header -->
        <div v-if="!unitAttemptResults" class="container">
            <div class="row">
                <div class="col-sm-8 hidden-xs hidden-sm">
                    <h2 style="margin-bottom: 0">Quiz on {{unit.title}}</h2>
                    <div>by {{unit.author}} &bull; {{quiz.questions.length}} questions</div>
                    <br>
                </div>
            </div>
        </div>
        <!-- Question & answers -->
        <div v-if="!unitAttemptResults" class="container">            
            <h3 v-html="md2h(curQuestion.text)" style="margin-top: 0" id="question"></h3>
            <button @click="chooseAnswer(ind)" v-for="(answer, ind) in curQuestion.answers" :class="buttonClasses(ind)" style="margin-bottom:10px; text-align:left; height: auto; display: flex; align-items: center">
                <span style="font-size:2em; font-weight:bold; display: inline-block; vertical-align: middle; margin: -10px; padding-right:20px">{{ABC[ind]}}</span>
                <span v-html="md2h(answer.text)" style="flex-grow: 1"></span>
            </button>
            <p>&nbsp;</p>
            <h3 v-show="revealAnswers && chosenAnswer.correct" style="color:#070; font-weight:bold"><!--{{chosenAnswer.text}}-->{{ABC[chosenAnswerInd]}} is CORRECT!</h3>
            <h3 v-show="revealAnswers && !chosenAnswer.correct" style="color:#700; font-style:italic">Sorry, but {{ABC[chosenAnswerInd]}} is incorrect.</h3>
            <p v-if="curQuestion.mekoros && (revealAnswers || showMekorosInAdvance)"><b>Source:</b> <span v-html="md2h(curQuestion.mekoros)"></span></p>
            <div v-if="curQuestion.afterInfo && revealAnswers && curQuestion.afterInfo" class="panel panel-info">                
                <div class="panel-body">
                    <!--<b>NOTE:</b> --><span v-html="md2h(curQuestion.afterInfo)"></span>
                </div>
            </div>
            <div id="nextQuestionButton" style="padding-bottom: 1em">
              <button @click="nextQuestion" v-show="revealAnswers" class="form-control btn-primary" style="font-size:25px; height: 65px">Next Question &gt;&gt;</button>
            </div>
        </div>
        <!-- Results -->
        <div v-if="unitAttemptResults" class="container animated" :class="{zoomInUp: unitAttemptResults}">
            <div style="text-align:center; margin-bottom:25px">       
                <div v-if="unitAttemptResults.passed" style="font-size:3.5em; font-weight:bold; color:#3A3;">Well done! You passed!</div>
                <div v-else style="font-size:3.5em; font-weight:bold; color:#773;">Almost there, צדיק!</div>
                <div style="font-size:2.2em; font-weight:bold;">Your score: {{unitAttemptResults.scorePercent}}% ({{unitAttemptResults.questionsCorrect}}/{{unitAttemptResults.questionsTotal}})</div>
                <div>
                    <img v-for="star in unitAttemptResults.stars" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Gold_Star.svg/1000px-Gold_Star.svg.png" style="width:70px; height: 70px" :style="(unitAttemptResults.stars < 3) ? 'filter: gray; -webkit-filter: grayscale(1); filter: grayscale(1)' :''" :key="star"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">
                    <a onclick="location.reload()" class="form-control btn" :class="{'btn-primary': !unitAttemptResults.passed}" style="margin-bottom:15px">Try again!</a>
                </div>
                <div class="col-sm-3">
                    <a href="#!/scorecard" class="form-control btn" :class="{'btn-primary': unitAttemptResults.passed}" style="margin-bottom:15px">See All Topics</a>
                </div>
            </div>
        </div>


    <!-- <h1>Quiz: {{unit.title}}</h1>
    <div style="font-style:italic">{{unit.author}}</div>
    <p>&nbsp;</p>
    <div class="alert alert-info">
      <span class="hidden glyphicon glyphicon-warning-sign"></span> 
      The quiz doesn't actually work yet -- this is just to show that the unit was passed to us correctly.
    </div>
    <ul>
      <li v-for="q in unit.questions" v-html="md2h(q.text)"></li>
    </ul> -->
      
  </div>
  <div v-else class="container">    
    <h3 v-if="unit">This topic has no quiz yet. Sorry! Check back soon...</h3>
    <div v-else>
        <h3>Whoops!</h3>
        <p>There was an error loading that topic. Perhaps you followed a broken link?</p>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import marked from "marked"

export default {
  data() {
    return {      
      sounds: {
        correct: new Audio('/assets/correct.wav'),
        incorrect: new Audio('/assets/incorrect.wav')
      },
      ABC: ['A','B','C','D','E','F','G','H','I','J'],
      curQuestionInd: 0,
      chosenAnswerInd: 0,       
      showMekorosInAdvance: false,
      curScore: 0,         
      revealAnswers: false,
      unitAttemptResults: null
    }    
  },
  computed: {
    curQuestion: function() { return this.quiz.questions[this.curQuestionInd] },
    chosenAnswer: function() { return this.curQuestion.answers[this.chosenAnswerInd] }
  },
  methods:{    
    md2h: function(inputMarkdown) {
      //   return (<any>marked).inlineLexer(markdown, [])
      return marked(inputMarkdown, { sanitize: false }).replace('<p>','').replace('</p>',"       ").trim().replace("       ","<br/><br/>") 
    },
    percentToStars(percent) {
      if (percent>=95) return 3
      if (percent>=80) return 2
      if (percent>=85) return 1
      return 0
    },    
    chooseAnswer: function(ind) {
        if (this.revealAnswers) return;
        this.chosenAnswerInd = ind
        this.revealAnswers = !this.revealAnswers                    
        if (this.chosenAnswer.correct)  this.curScore+=1;
        this.sounds[this.chosenAnswer.correct ? 'correct' : 'incorrect'].play()                    
        //alert(chosenAnswer.text + ' is ' + (chosenAnswer.correct ? 'CORRECT!' : 'WRONG!'))
        this.$nextTick(() => document.getElementById('nextQuestionButton').scrollIntoView(false))
    },
    nextQuestion: function() {
        this.revealAnswers = false
        this.curQuestionInd+=1
        if (this.curQuestionInd==this.quiz.questions.length) {
            //End of quiz
            //this.curQuestionInd=0
            var scorePercent = Math.round(100 * this.curScore / this.quiz.questions.length)
            this.unitAttemptResults = {
                scorePercent: scorePercent,
                stars: this.percentToStars(scorePercent),
                passed: scorePercent>=95,
                questionsCorrect: this.curScore,
                questionsTotal: this.quiz.questions.length
            }
        } else {
          this.$nextTick(() => document.getElementById('question').scrollIntoView(true))
        }
    },
    buttonColorClass: function(buttonIndex) {
        if (!this.revealAnswers) return 'btn-primary';
        if (this.curQuestion.answers[buttonIndex].correct) return 'btn-success';
        if (this.chosenAnswerInd==buttonIndex) return 'btn-danger';
        return 'btnHalachaGrayed';
    },
    buttonClasses: function(buttonIndex) {
        var ret = {
            choiceButton: true,
            animated: true, 
            'form-control': true,                        
            answersRevealed: this.revealAnswers                        
        }    
        ret[this.buttonColorClass(buttonIndex)] = true //add appropriate colour
        if (this.revealAnswers && buttonIndex==this.chosenAnswerInd) {
            // if he just clicked this button, animate it accordingly
            ret[this.chosenAnswer.correct ? "tada" : "flash"] = true
        }
        return ret
    }
  },
  props: {
    unit: Object,
    quiz: Object,
  }
}
</script>

<style scoped>
    
  .choiceButton {transition: background 0.4s, opacity 0.4s}
  .choiceButton:not(.answersRevealed) { background:#8b4ac5}
  .choiceButton.btnHalachaGrayed {opacity: 0.6}
  
  .bigCircle { border: 2px groove #AAA; background:#8b4ac5; color:#FFF; border-radius:200px; padding: 10px 15px }
    
</style>
