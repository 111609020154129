



































































import { Component, Vue } from 'vue-property-decorator';
import NavBar from './components/NavBar.vue';
import Quiz from './components/Quiz.vue';
import UnitGallery from "./components/Home_UnitGallery.vue"
import WikiPage from "./components/WikiPage.vue"
import Scorecard from "./components/Scorecard.vue"
import MenahelUnits from "./components/MenahelUnits.vue"
import UnitEditor from "./components/UnitEditor.vue"
import UserRow from "./components/Home_UserRow.vue"
import UnitViewer from "./components/UnitViewer.vue"
import {HUnit} from "./UnitConverter"; 
import {AppState} from "./main"


@Component({
  components: {
    NavBar, Quiz, UnitGallery, WikiPage, Scorecard, UserRow, UnitViewer, UnitEditor, MenahelUnits
  },
})
export default class App extends Vue {
  // Handle hash changes...
  hash = ""
  mounted() {
  
    let updateHash = () => {
      this.hash = (<any>window.location.hash).startsWith('#!/') ? window.location.hash.substr(3) : ''      
      //GA
      const w: any = window            
      if (w.gtag) {
        const path = window.location.pathname + (this.hash && window.location.hash)
        console.log("Logging a page view to", path)
        w.gtag('config', w.gaTrackerId, {'page_path': path})
        // w.ga('set', 'page', path)
        // w.ga('send', 'pageview')  
      }      
    }
    window.addEventListener("hashchange", updateHash)
    updateHash()
  }

  unitByName(name:string):HUnit|null {
    if (name=="sample") return this.unitByName('chanukah')
    //Search
    let findIt = AppState.unitCache.filter(x=>x.code==name)
    if (findIt.length==1) return findIt[0]
    //throw "Unit not found; only 'sample' supported"    
    return null    
  }

  get hashParts() { return (this.hash || '').split('/') }
  get itemName() { return this.hashParts[1] }
  get curUnit() { return this.unitByName(this.itemName) }
}

// export default {
//   data: function() {
//     return {
//         msg: "Test"    
//     }
//   },
//   components: {HelloWorld}
// }
