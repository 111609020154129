<template>
   <div class="row">
              <div class="col-md-3">
                          <!--User card-->
                          <div class="basic-card ourCard" style="background:#EEEEEE">
                            <span class="pull-right" style="font-size:2.6em; opacity:0.7"><span class="glyphicon glyphicon-user"></span></span>
                            <div style="font-size:1.5em; font-weight: bold">Hi, Asher</div>
                            <div style="color:#999; text-transform: uppercase">Member since 2009</div>
                            <div style="font-weight:bold">Pick up where you left off:</div>
                            <ul style="font-weight: bold; padding-left: 1.8em">
                              <li><a href="#!/unit/sample">Hilchos Yichud</a></li>
                              <li><a href="#!/unit/sample">Hilchos Chanukah</a></li>
                            </ul>
                            <div>
                              <a href="#!/scorecard" class="btn btn-primary" style="padding:2px 5px">My Progress</a>
                            </div>
                          </div>                    
              </div>
              <div class="col-md-3">
                          <!--REVIEW card-->
                          <div class="basic-card ourCard">
                            <div class="colorfulHeader">Review</div>
                            <div style="font-style:italic; color:#999">Don't plant without reaping!</div>
                              <ul style="font-weight: bold; list-style:none; padding-left:0">
                                <table style="width:auto">
                                  <tbody>
                                    <tr>
                                      <td style="text-align:right"><span class="badge" style="font-size:0.8em; background: #E65">3 mo.</span></td>
                                      <td style="padding-left:5px"><a href="#!/unit/sample">Meleches Borrer</a></td>
                                    </tr>
                                    <tr>
                                      <td style="text-align:right"><span class="badge" style="font-size:0.8em; background: #DA5">23d</span></td>
                                      <td style="padding-left:5px"><a href="#!/unit/sample">Halachos of Elul</a></td>
                                    </tr>
                                  </tbody>
                                </table>                                
                              </ul>
                          </div>
              </div>
              <div class="col-md-3">
                          <!--NEXT UP card-->
                          <div class="basic-card ourCard">
                            <div class="colorfulHeader" style="border-color: #152">Next up</div>
                            <div style="color:#999">
                              Try these topics next:
                              <a href="#!/scorecard" class="pull-right" style="text-transform:uppercase;">See all &gt;&gt;</a>
                            </div>                            
                            <ul style="font-weight: bold; padding-left: 1.8em">
                              <li><a href="#!/unit/sample">Hilchos Succos</a></li>
                              <li><a href="#!/unit/sample">Sefiras Ha'Omer</a></li>
                              <li><a href="#!/unit/sample">Halachos of Meat and Fish</a></li>
                            </ul>
                          </div>
              </div>
              <div class="col-md-3">
                          <!--CHALLENGE card-->
                          <div class="basic-card ourCard">
                            <div class="colorfulHeader" style="border-color: rgb(221, 170, 85)">Random Challenge</div>
                            <div style="font-style:italic; color:#999">From <a href="#!/unit/sample" style="font-weight:bold; color:#999">Hilchos Chanukah</a></div>
                            <div style="font-weight:bold">May one light one light from another?</div>
                            <div>
                              <button class="btn btn-default input-sm" style="padding: 3px 10px; width: 2em; margin-right:4px" onclick="alert('Coming soon bli neder...')">A</button>
                              Yes
                            </div>
                            <div>
                              <button class="btn btn-default input-sm" style="padding: 3px 10px; width: 2em; margin-right:4px" onclick="alert('Coming soon bli neder...')">B</button>
                              No
                            </div>                            
                            <div>
                              <button class="btn btn-default input-sm" style="padding: 3px 10px; width: 2em; margin-right:4px" onclick="alert('Coming soon bli neder...')">C</button>
                              Only on the last day of Chanukah
                            </div>                            
                            <div>
                              <button class="btn btn-default input-sm" style="padding: 3px 10px; width: 2em; margin-right:4px" onclick="alert('Coming soon bli neder...')">D</button>
                              Only on the first day of Chanukah
                            </div>                            
                          </div>
              </div>
	</div>
</template>

<script>
    export default {
        props: {
			user: Object
		}
    }
</script>