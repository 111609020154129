<template>  
    <div>        
        <a v-for="(slide,ind) in unit.slides" class="step" :href="`${unit.viewUrl}/${ind+1}`" :class="{active: slide===curSlide}" :key="ind">
            <span class="insideTheStep">
                <span class="circleNumber">
                    <span v-if="slide.done" class="glyphicon glyphicon-ok"></span>
                    <span v-else>{{ind+1}}</span>
                </span>
                <span class="stepName" :style="slide.done ? 'opacity:0.65; font-style: italic' : null">{{slide.title}}</span>
            </span>            
        </a>
        <a v-if="unit.cachedQuiz" class="step" :href="unit.quizUrl">
            <span class="insideTheStep">
                <!--<span class="circleNumber" style="border: 3px solid #E7E">T</span>-->
                <span class="circleNumber"><span class="glyphicon glyphicon-tower"></span></span>
                <span class="stepName" style="">Take the test!</span>
            </span>
        </a>                      
  </div>  
</template>

<script>

import Vue from 'vue'

export default {
  data() {
    return {          
    }    
  },    
  props: {
    unit: Object,
    curSlide: Object
  }  
}
</script>

<style scoped>      
    .step {display:block; color:#555; }
    .step:not(:last-child) {min-height: 4em}
    .circleNumber {display: inline-block; border-radius:50px; border: 0.1em solid #AAA; width: 2em; height: 2em; text-align: center; font-size: 1.3em; font-weight: bold; vertical-align: top; margin-right: 0.5em; background: #EEE;}  /*margin-left: -1.07em;*/
    .step.active .circleNumber, .step:hover .circleNumber {background:#999; color:#EEE}
    .stepName {display:inline-block; vertical-align: top; margin-top:5px;}
    .step.active .stepName, .step:hover .stepName { font-weight:bold;}
    /* Give steps a border, but have the border begin in middle of the circle. 
    We do this by giving them a border of 'bw' (0.2em), but then a margin-left of 'm' (1.1em),    
    Then making the *contents* of the step get a negative margin-left: -(bw + m)    
    */
    .step  {border-left: 0.2em solid #AAA; margin-left: 1.1em; }
    .step .insideTheStep {margin-left:-1.3em}
    /*.alert a {text-decoration: underline}*/
</style>
