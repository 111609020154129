













import Component from "vue-class-component";
import Vue from "vue";
import marked from "marked";

@Component({
    props: {
        pageId: String
    }
})
export default class WikiPage extends Vue {
    pages = {
        Contribute: marked(`
We are in need of Rabbonim and Avreichim who can dedicate their time toward authoring, editing and reviewing high-quality Halacha material. If you have any ideas, please **[contact us](javascript:contactUs())**!
`),

        About: marked(`
**Halacha Academy** is a groundbreaking project that aims to make serious and accurate Halacha learning accessible to the masses. We plan to offer:

#### A single source for all essential Halacha
- We are gathering and producing **simple and concise Halacha literature** on a wide variety of topics
- Topics are broken down into **bite-sized pages** so you can easily grasp it before moving on

#### A program designed to help you succeed
- **Quiz questions** test your knowledge, as well as bring it to the practical level
- Automated **chazzara reminders** to make the Halacha yours

#### Perfect for daily study during free time
- **Pick up where you left off** at any time
- In just a **few minutes per day** you can accomplish a lot
- We remember your progress so you know what you've already done

We're currently hard at work making the Halacha Academy vision a reality. If you'd like to get involved, please **[contact us](javascript:contactUs())**!

We've already got plenty of material online. Why not start today? Check out our **[list of topics](#!/scorecard)**.
        `)

    }
    
}
