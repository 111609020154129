
















import Vue from "vue";

export default Vue.extend({
    props: {
        unit: Object,
        hideGroupOfTitle: Boolean
    },
    computed: {
        splitTitle: function() {                 
            const t: string = this.unit.title
            if (!this.hideGroupOfTitle) return t
            const pos = t.indexOf(":")
            return pos < 0 ? t : t.substr(pos+1).trim()
        }
    }        
})
