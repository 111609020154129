










import * as Formatter from '../Formatter' 
import Vue from 'vue';

export default Vue.extend({    
    props: {    
        slide: Object,
        showTitle: {type: Boolean, default: true},
        //hideAnnotations: {type: Boolean, default: undefined /* which leaves the default of Formatter */},
        //footnotesAtBottom: {type: Boolean, default: undefined /* which leaves the default of Formatter */},
        formatterOptions: {type: Object, default: ()=>({})},
    },    
    computed: {
        bodyHalves: function () {                
            const allHtml = Formatter.format(this.slide.body || "", this.formatterOptions.footnotesAtBottom, this.formatterOptions.hideAnnotations, this.formatterOptions.hideComments)
            const parts = allHtml.split("<!--Footnotes-->")
            if(parts.length==1) parts.push("")                
            return parts
        }
    },
    methods: {                  
        marked_inline(inputMarkdown: string) {
            //   return (<any>marked).inlineLexer(markdown, [])
            return Formatter.format(inputMarkdown).replace('<p>','').replace('</p>',"       ").trim().replace("       ","<br/><br/>") 
        }
    }
})
