import * as marked from 'marked'



export function intToHeb(num: number, quoteizeAlso = false) {
    num = Math.round(num)
    if (num < 1) return num
    var out = ""
    const perform = (value: number, char: string) => {
        while (num >= value) { out += char; num -= value }
    }
    const performMultiple = (tenity: number, chars: string) => {
        chars.split('').reverse().forEach((char, ind) => perform((chars.length - ind) * tenity, char))
    }
    performMultiple(100, "קרשת")
    performMultiple(10, "יכלמנסעפצ")
    performMultiple(1, "אבגדהוזחט")
    out = out.replace("רע", "ער")
    out = out.replace("י" + "ו", "טז")
    out = out.replace("י" + "ה", "טו")
    return quoteizeAlso ? quoteize(out) : out
}

export function quoteize(word: string, evenOneLetter = true) {
    if (evenOneLetter && word.length == 1) return word + "'"
    if (word.length <= 1) return word
    return word.substr(0, word.length - 1) + '"' + word.substr(-1)
}

function transformOneElement<T>(array: T[], elementInd: number, transformer: (original: T) => T) {
    if (elementInd < 0) elementInd = array.length - -elementInd //-1 means last item, -2 means 2nd to last, etc.
    return array.map((el, ind) => (ind == elementInd) ? transformer(el) : el)
}

export function replacements(input: string) {
    const transformLastWord = (sentence: string, func: (s: string) => string) => transformOneElement(sentence.split(" "), -1, func).join(" ")
    const transformInt = (int:string|number, replacement:string) => {
        var word = replacement + intToHeb(Number(int))
        const jumbleTogether = replacement.indexOf(" ") == 0
        return jumbleTogether ? quoteize(word) : transformLastWord(word, quoteize)                
    }

    const replaceWord = (word: string) => {
        const upperWord = word.toUpperCase()

        const charsAndCustom= (regExp:RegExp,func:(parts:string[])=>string) => {
            const check = regExp.exec(word)
            if(!check || !word || check.index!=0 || check[0].length!=word.length) return false
            word = func(check)
            return true
        }
        if (charsAndCustom(/(OC|YD|CHM|EH)([0-9]+)?(\:|sk)?([0-9]+)?(U)?(BHMB|BH|BMB)?|/i,p=>{
            const hebrewSeferNames:any = {OC: `או"ח`, YD: `יו"ד`, CHM: 'חו"מ', EH: 'אה"ע'}
            const seferName = hebrewSeferNames[p[1].toUpperCase()]
            const numberOne = p[2] ? " " + transformInt(p[2],"סי' ") : ""
            const separator = p[3]
            const numberTwo = (separator && p[4]) ? " " + transformInt(p[4],(p[3]=="sk") ? 'מ"ב סק' : 'ס') : ""            
            const vav = p[5] ? "ו" : ""
            const beis = p[6] ? ({BMB: 'מ"ב', BH: 'בהג"ה', BHMB: 'בהג"ה ובמ"ב'} as any)[p[6].toUpperCase()] : ""
            const ret = seferName + numberOne + numberTwo + ((vav||beis) ? " " : "") + vav + beis
            return ret
            //for debugging: return ret + " -- " + p.join(",")
        })) return word

        const simpleReplacement = (from: string, to: string) => upperWord == from ? ((word = to), true) : false
        /*if (simpleReplacement("OC", 'או"ח')) return word
        if (simpleReplacement("EH", 'אה"ע')) return word
        if (simpleReplacement("CHM", 'חו"מ')) return word
        if (simpleReplacement("YD", 'יו"ד')) return word*/
        if (simpleReplacement("MB", 'מ"ב')) return word
        if (simpleReplacement("RMA", 'רמ"א')) return word
        if (simpleReplacement("BAHAGA", 'בהג"ה')) return word
        const charsAndNumber = (chars: string, replacement: string) => {
            const foundPrefix = upperWord.substr(0, chars.length) == chars
            //if (foundPrefix) { console.log(`'${word}' begins with ${chars}`) } else { console.log(upperWord) }
            if (foundPrefix && parseInt(word.substr(chars.length)) > 0) {
                word = replacement + intToHeb(parseInt(word.substr(chars.length)))
                const jumbleTogether = replacement.indexOf(" ") == 0
                word = jumbleTogether ? quoteize(word) : transformLastWord(word, quoteize)
                return true
            } else { return false }
        }
        if (charsAndNumber("SI", "סי' ")) return word
        if (charsAndNumber("SK", `סק`)) return word
        if (charsAndNumber("S", 'ס')) return word

        //Nothing matched... just return as is
        return word
    }

    const replaceLine = (line: string) => line.split(" ").map(replaceWord).join(" ")
    const lines = input.split("\n")
    return lines.map(replaceLine).join("\n")
}

export function format(input: string, footnotesAtBottom = true, hideAnnotations = (process.env.NODE_ENV == "production"), hideComments = false) {    
    // Lines beginning with ! or ?
    const replaceFirstChar = (firstChars: string, replacer: (restOfLine: string) => string) => {
        const escapeEachChar = (str: string) => str.split('').map(x => "\\" + x).join('')
        //Any whitespace at the start of a line, followed by the escaped line starting chars, followed by any text.
        //Now also matching the linebreak at the end, and re-adding it after. Reason is, so that when hiding markings we can hide the linebreak too.
        input = input.replace(new RegExp(`^[\t ]*${escapeEachChar(firstChars)}.*\n`, "mg"), found => {
            if (hideAnnotations) return ""
            found = found.substr(found.indexOf(firstChars) + firstChars.length).trim()
            var ret = replacer(found) 
            if (ret) ret += "\n"
            return ret
        })
    }
    replaceFirstChar("!!--", line => hideComments ? '' : `<span style='display:inline-block; background: #EFE !important; border: 1px dashed #9B9; padding: 0 3px; border-radius: 7px; font-size:0.8em; margin-bottom:0.5em'>${line}</span>`)
    replaceFirstChar("!!", line => `<span style='display:inline-block; background: #EEF !important; border: 1px solid #AAF; padding: 0 3px; border-radius: 7px; font-size:0.8em; margin-bottom:0.5em'>${line}</span>`)
    replaceFirstChar("?", line => `<span style='display:inline-block; background: #FFD !important; border: 1px solid #FAA; padding: 0 3px; border-radius: 7px; font-size:0.8em; margin-bottom:0.5em'>${line}</span>`)
    replaceFirstChar("[ ] ", line => `<input type="checkbox" disabled> ${line}`)
    replaceFirstChar("[X] ", line => `<input type="checkbox" disabled checked> ${line}`)
    replaceFirstChar("[x] ", line => `<input type="checkbox" disabled checked> ${line}`)

    // 'torn-paper' effect
    //html = html.replace("<pre><code>",`<div class="torn-paper"><div class="torn-paper-inner">`).replace("</code></pre>","</div></div>")
    input = input.replace(/```p\n/g,`<div class="torn-paper" dir="rtl"><div class="torn-paper-inner">\n\n`).replace(/\np```\n/g,"\n</div></div>\n")
    
    // Convert markdown
    let html = marked(input, { sanitize: false, gfm: true, breaks: true })

    // Parens ending in ? 
    html = html.replace((/\([^\)]*\?\)/g), found => {
        return hideAnnotations ? '' : `<sup style='background: #FFD !important; border: 1px solid #FAA; font-size:0.9em'>${found}</sup>`
    })

    // Footnotes
    var bottomFootnotes: string[] = []
    html = html.replace((/\{\^[^\}]*\}/g), found => {
        found = found.substr(2, found.length - 3)
        found = replacements(found)
        // OLD: If the last character is a quote etc., move it to the end -- it will mess up in LTR
        // if (found.substr(-1) == "'" || found.substr(-1) == '"') found = found.substr(-1) + found.substr(0, found.length - 1)
        // NEW: If it's in Hebrew, wrap in a RTL span
        const isHebrew = found[0] >= "א" && found[0] <= "ת"
        const found_wrapped = isHebrew ? `<span dir="rtl">${found}</span>` : found
        // Output in the right way/place
        if (footnotesAtBottom) {
            bottomFootnotes.push(found_wrapped)            
            // Added "ltr" so it shows up properly amid mixed mainly-English text (in the עירוב22 we want the 22 to be AFTER עירוב). (The contents can still be rtl, se above.)
            const found_attrEncoded = found.replace(/\&/g,"&amp;").replace(/</g,"&lt;").replace(/>/g,"&gt;").replace(/\"/g, "---")
            return `<sup dir="ltr" title="${found_attrEncoded}">${bottomFootnotes.length}</sup>`
        } else {
            return `<sup dir="ltr" style='border: 1px dotted #777; background: #EEE !important;'>${found_wrapped}</sup>`
        }
    }) + (!bottomFootnotes.length ? "" : "<!--Footnotes--><ol style='border-top:1px solid #DDD; opacity: 0.8; font-size: 0.8em; padding-top: 1em; line-height:1.4'>\n" + bottomFootnotes.map((f, ind) => `<li>${f}</li>`).join("\n") + "\n</ol>")

    return html
}