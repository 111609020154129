export class HQuiz {
    questions: HQuizQuestion[] = []
}
export class HQuizQuestion {
    text?: string = ""
    mekoros: string = ""
    afterInfo: string = ""
    answers: { text: string, isCorrect?: boolean }[] = []
}
export class HSlide {
    title: string = "Untitled Slide"
    body = "No info yet"
}

export interface HUnit {
    title: string,
    checkedOver?: boolean,
    hebrewTitle?: string,    
    errors?: { lineNumber: number; desc: string }[],
    imageUrl?: string,
    author: string,
    code: string
    hidden?: boolean
    bestScore?: IScore,
    lastScore?: IScore
    completed?: boolean
    inProgress?: boolean,
    cachedQuiz?: any,
    materialUrl?: string,
    slides?: HSlide[],
    viewUrl?: string,
    quizUrl?: string
}

export interface IScore {
    stars: number,
    percent: number,
    date?: string
}


export class UnitConverter {
    convert(text: string) {
        return this.oldProcessMarkdownFile(text)
        // const ret: HUnit = { title: "", author: "", code: "" }
        // var curSlide: HSlide | null = null
        // for (const l of text.split("\n")) {
        //     const startsWith = (str: string) => l.substr(0, str.length) == str ? l.substr(str.length).trim() : ""
        //     if (startsWith("Unit:"))
        //         ret.title = startsWith("Unit:")
        //     else if (startsWith("Author:"))
        //         ret.author = startsWith("Author:")
        //     else if (startsWith("#")) {
        //         curSlide = new HSlide()
        //         curSlide.title = startsWith("#")
        //     }
        //     else {
        //         if (curSlide != null)
        //             curSlide.body += l + "\n"
        //         else if (l == "") {
        //             //Blank line -- ignore
        //         } else
        //             throw "First define a slide using '# SlideTitle'"
        //     }
        // }

        // return ret
    }


    oldProcessMarkdownFile(data: string) {
        //=================  pre-processing  =================
        // First normalize line endings to \n. Found this regex at https://github.com/ryanve/eol/blob/gh-pages/eol.js
        data = data.replace(/\r\n|\r|\n/g, '\n') //old code: data.replace("\n\r","\n").replace("\r\n","\n").replace("\r","\n")

        // FORMERLY: Get everything after the line "# Questions"     
        //data = data.split('\n# Questions\n')[1]
        //if (!data) throw "Error processing HalachaUnit: Line '# Questions' not found"
        //But now we do this with a flag below, because we want to process certain things like title and author.
        //console.log(data)
        function appendLine(str: string, strToAppend: string, trim = true) {
            strToAppend = trim ? strToAppend.trim() : strToAppend
            return !!str ? (str + '\n' + strToAppend) : strToAppend
        }
        // Split into lines    
        var lines = data.split('\n')   // old: .split(/\r?\n/)

        //=================  go through the lines of the file  =================
        var curUnit = { cachedQuiz: new HQuiz(), slides: [] as HSlide[], errors: [] as {desc: string, lineNumber: number }[], title: "" }
        var curQuestion: HQuizQuestion | null
        var curSlide: HSlide | null = null
        //old: var areWeInQuestionSection = false
        lines.forEach((line, ind) => {
            //console.log('Beginning line '+line.length+' '+ line.charCodeAt(0) +': '+line)            
            const lineNotTrimmed = line
            const lineNoFormatting = line.replace(/\_|\*/g, "") //could just use: .match(/(\!\?|\?[\)\]\}\?])/g)
            if ("?) ?] ?) ?} ?? !?".split(" ").some(x => lineNoFormatting.indexOf(x) >= 0)) {                
                curUnit.errors.push({ desc: `Contains question mark: "${line}"`, lineNumber: ind+1 })
            }
            line = line.trim();
            const raiseErr = (desc: string) => { throw `Error on line ${ind+1}: ${desc}`  }// curUnit.errors.push({ lineNumber: ind + 1, desc })
            const startsWith = (str: string, sourceString = line) => sourceString.substr(0, str.length) == str ? sourceString.substr(str.length).trim() : ""
            if (startsWith("!") && !startsWith("!!")) {
                var cmd = startsWith("!")
                const findProp = "imageUrl,code,hebrewTitle,materialUrl".split(",").filter(p => startsWith(p, cmd))[0]
                //debugger
                if (cmd == "hidden" || cmd == "checkedOver") {
                    raiseErr(`You can no longer specify command '${cmd}' in Markdown files`) //curUnit[cmd] = true
                } else if (startsWith("?", cmd)) {
                    //questionMark item was added already above, so just skip the line
                } else if (findProp) {
                    (curUnit as any)[findProp] = startsWith(findProp, cmd)
                } else {
                    raiseErr("Unrecognized command: " + line)
                }
            } else if (startsWith("# Unit:")) {
                curUnit.title = line.split("# Unit:")[1].trim()
            } else if (startsWith("Author:")) {
                raiseErr(`You can no longer specify metadata 'Author' in Markdown files`) //curUnit.author = line.split("Author:")[1].trim()
            } else if (line == "# Quiz") {
                //just ignore, this is old
            } else if (line.substr(0, 6) == "### Q:") {
                // if no answers yet: it's a multi-line question. Add to it
                const qTxt = line.substr(6).trim()
                if (curQuestion && !curQuestion.answers.length) {
                    curQuestion.text = appendLine(curQuestion.text!, qTxt)
                } else { // new question
                    curQuestion = new HQuizQuestion()
                    curQuestion.text = qTxt
                    curUnit.cachedQuiz.questions.push(curQuestion)
                }
            } else if (startsWith("#") && !(startsWith("##"))) {
                curQuestion = null
                //Add a slide
                curSlide = new HSlide(); curSlide.title = startsWith("#"); curSlide.body = ""
                curUnit.slides.push(curSlide)
            } else if (curQuestion && line.substr(0, 3) == "{A}") {
                curQuestion.afterInfo = appendLine(curQuestion.afterInfo, line.substr(3))
            } else if (curQuestion && line.substr(0, 3) == "{M}") {
                curQuestion.mekoros = appendLine(curQuestion.mekoros, line.substr(3))
            } else {
                if (curQuestion && line != "") {
                    //add an answer to current question
                    var newAnswer = { text: line, correct: false }
                    curQuestion.answers.push(newAnswer)
                    // if @ at the beginning of line: mark as correct answer, and remove the @
                    if (newAnswer.text.substr(0, 1) == "@") { newAnswer.correct = true; newAnswer.text = newAnswer.text.substr(1) }
                } else if (curSlide) {
                    //Add to current slide
                    curSlide.body = appendLine(curSlide.body, lineNotTrimmed, false)
                } else if (line != "") {
                    throw "Error processing HalachaUnit: Text in middle of nowhere -- Specify a slide with '# SlideTitle' or a question with: '### Question text'. Line follows: " + line
                }
            }
        });

        //===============  done!  =================
        return curUnit
    }
}

