<template>
  <div v-if="!unit" class="container">
    <h1>Whoops!</h1>
    <p>There was an error loading that topic. Perhaps you followed a broken link?</p>
  </div>
  <div v-else class="container">
    <div class="row">
        <div class="col-md-4 hidden-xs hidden-sm" :class="{affix: makeMapFixed}" style="padding-top:1em; /*border-right:1px solid #CCC*;/ ">
            <p>
                <span style="font-size:2em">{{unit.title}}</span><br/>
                <span style="display: inline-block; /*margin-top:-5px*/">by {{unit.author}}</span>
            </p>
            <div :class="{mapScrollContainer: makeMapFixed}">
                <UnitViewerSlideMap :unit="unit" :curSlide="curSlide" />
            </div>
            <div style="height:2em">&nbsp;</div>
        </div>
        <div class="col-md-8" :class="{'col-md-offset-4': makeMapFixed}">
            <div v-if="unit && !unit.checkedOver" class="alert alert-danger"><b>WARNING:</b> This topic has not yet been checked over by a Posek. Use at your own risk.</div>
            <div v-if="curSlide">
                <!-- <table style="width:100%">
                    <tr>
                        <td><button class="btn" @click="curSlideInd--"><span class="glyphicon glyphicon-backward"></span></button></td>
                        <td width="100%" align="center">
                            <select v-model="curSlideInd" style="margin-left:1em; display:inline-block; font-size:2.6em; height: 2em; margin-left: 0.5em; color: #444; line-height: 1em; width: auto" class="form-control">
                                <option v-for="(slide,ind) in unit.slides" :key="ind" :value="ind.toString()">{{slide.title}}</option>
                            </select>                
                        </td>
                        <td><button class="pull-right btn" @click="curSlideInd++"><span class="glyphicon glyphicon-forward"></span></button></td>
                    </tr>
                </table>-->
                <div>
                    <select v-model="curSlideInd" style="display:inline-block; font-size:2.6em; height: 2em; color: #444; line-height: 1em; width: auto; vertical-align: middle; max-width: 80%" class="form-control">
                        <option v-for="(slide,ind) in unit.slides" :key="ind" :value="ind.toString()">{{slide.title}}</option>
                    </select>
                    <div class="pull-right" style="vertical-align: middle; display: inline-block; margin-top:1.2em">
                        <button class="btn" @click="curSlideInd--"><span class="glyphicon glyphicon-backward"></span></button>
                        <button class="pull-right btn" @click="curSlideInd++"><span class="glyphicon glyphicon-forward"></span></button>
                    </div>
                </div>
                <UnitSlideViewer :slide="curSlide" :showTitle="false">
                    <div>
                        <!--NEXT button-->
                        <p style="text-align: right">
                            <span @click="curSlide.done = true">
                                <!--<a :href="nextSlideUrl" class="btn btn-info" v-if="isLastSlide">
                                    <span style="font-size:1.3em">All done!</span><br/>
                                    <span class="glyphicon glyphicon-king"></span>
                                    &nbsp; Take the test &raquo;
                                </a>-->                            
                                <!--checkbox -->                        
                                <a :href="nextSlideUrl" :class="{checkOff: true, checked: curSlide.done}">
                                    <span class="box" style="margin-right:0.5em">
                                        <span class="check glyphicon glyphicon-ok"></span>
                                    </span>
                                    <span v-if="isLastSlide">All done -- take the quiz!</span>
                                    <span v-else>Continue</span>
                                    <span class="glyphicon glyphicon-chevron-right" style="margin-left:0.3em"></span>
                                </a>
                            </span>
                            <br/>
                            <br/>
                            <a href="javascript:void(0)" @click="questionOnThisPage" style="border-radius: 3px; background: #FFF; border: 1px solid #CCE; padding: 5px; color: #AAA; display: block; text-align: left">Ask a question on this page</a>
                        </p>
                    </div>
                </UnitSlideViewer>
            </div>
            <div v-else-if="unit.materialUrl">
                <h3>{{unit.title}}</h3>
                <p>The material for this section is available here:</p>
                <p><a :href="unit.materialUrl" class="btn btn-default" target="_blank"><span class="glyphicon glyphicon-file"></span>&nbsp; Open the material</a></p>
                <template v-if="unit.cachedQuiz">
                    <p>When you're finished, click below to take the test:</p>
                    <p><a :href="unit.quizUrl" class="btn btn-default"><span class="glyphicon glyphicon-king"></span>&nbsp; Take the test &raquo;</a></p>
                </template>
            </div>
            <div v-else-if="!(unit.slides && unit.slides.length)">                
                <h3>This topic has no material to study...</h3>
                <template v-if="unit.cachedQuiz">
                    <p><a :href="unit.quizUrl" class="btn btn-primary"><span class="glyphicon glyphicon-king"></span>Go straight to the test &raquo;</a></p>
                </template>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
//import marked from "marked"
import * as Formatter from '../Formatter' 
import UnitSlideViewer from "./UnitSlideViewer.vue"
import UnitViewerSlideMap from "./UnitViewer_SlideMap.vue"
import * as main from '../main'

export default {
  data() {
    return {          
        makeMapFixed: false
    }    
  },    
  props: {
    unit: Object,
    hashParts: null
  },
  components: {UnitSlideViewer, UnitViewerSlideMap},
  computed: {
      curSlideInd: {
            get: function() {          
                if(!this.hashParts || this.hashParts.length<3) return 0
                const ret = parseInt(this.hashParts[2])-1
                this.unit.inProgress = true
                main.localStorageSetting("progress_" + this.unit.code, { slideIndex: 0, date: new Date().getTime() }).modify(x => x)
                return ret
            },
            set: function(value) {
                if(!this.unit || !this.unit.slides) return;
                value = parseInt(value)                
                if (value < 0 || value >= this.unit.slides.length) return;
                window.location.href = `${this.unit.viewUrl}/${value+1}`
            }
      },
      curSlide: function() { return this.unit && this.unit.slides && this.unit.slides[this.curSlideInd] },      
      nextSlideUrl: function() {
        //New way -- simply:
        if(this.isLastSlide) return this.unit.quizUrl
        return `${this.unit.viewUrl}/${this.curSlideInd+1+1}` 

        //   //If not last slide, go to next slide
        //   if(!this.isLastSlide) {
        //       return '#!/unit/' + this.unit.code + '/' + (this.curSlideInd+1+1)
        //   } else {
        //       // Check if something wasn't done
        //       var ind=0
        //       for(const s of this.unit.slides) 
        //         if(!s.done) 
        //             return '#!/unit/' + this.unit.code + '/' + (ind+1)
        //         else
        //             ind++                    
        //       //All was done. Go to quiz  
        //       return this.unit.quizUrl
        //   }                        
          
          //Assign indexes to each slide          
          this.unit.slides.forEach((element,index) => element.ind = index)
          const urlForSlide = sl => `${this.unit.viewUrl}/${sl.ind+1}` 
          //If next slide wasn't done: next slide
          const comingSlides = this.unit.slides.filter(x=>x.ind>this.curSlide.ind)
          if (comingSlides[0] && !comingSlides[0].done) return urlForSlide(comingSlides[0])
          //Any coming slide that isn't done
          const comingSlidesNotDone= comingSlides.filter(x=>!x.done)
          if(comingSlidesNotDone[0]) return urlForSlide(comingSlidesNotDone[0])
          //First slide that isn't done
          const allSlidesNotDone = this.unit.slides.filter(x=>!x.done && x.ind!=this.curSlide.ind)
          if(allSlidesNotDone[0]) return urlForSlide(allSlidesNotDone[0])
          //If all are done: quiz
          return this.unit.quizUrl
      },            
      isLastSlide: function() { return this.curSlideInd == (this.unit.slides.length-1) }
  },
  methods: {      
      questionOnThisPage() {
        contactUs()
        $crisp.push(['set', 'message:text', [`Question on "${this.unit.title}" page "${this.curSlideInd+1}: ${this.curSlide.title}:"\n\n`]])
      }
  }
}
</script>

<style scoped>      
    .mapScrollContainer {overflow-y: auto; max-height: 70vh; margin-right: 4em}

    /* "Continue" checkbox */
    .checkOff{font-size: 18pt; text-decoration: none; color:#444}
    .checkOff .box {border:4px solid #777; padding: 3px; width:45px; height:45px; display:inline-block; overflow:visible; vertical-align: bottom; transition: background-color 0.3s; }
    .checkOff:not(.checked):not(:hover) .check {opacity: 0}
    .checkOff .check {font-size:1.4em; position: relative;top: -2px; left: -3px; display: inline-block;  transition: opacity 0.2s}
    /*.checkOff:hover {color:#171; }*/
    .checkOff:hover .box {/*background: #9D9;*/ color:#252; }
</style>