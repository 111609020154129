















































































































































































import Component from "vue-class-component";
import Vue from "vue";
import { UnitConverter, HUnit, HSlide } from "../UnitConverter";
import UnitSlideViewer from "./UnitSlideViewer.vue"
import { debounce, makeComparer } from "../main";

const defaultText = `# My First Slide

Type here; it should be updated in real-time.4

This is a paragraph that is
wrongly broken up
onto three lines, and can be fixed.

On the other hand these are two paragraphs.3
Note that annotations are supported (they are?)

Footnote 2 (F2 to create ambiguity) will be detected as having multiple possible references. Luckily, you can resolve this in one click using our GUI.2

Footnote 3 won't have any problems, because we mark it with brackets[3]. So this three3 is not ambiguous.

Footnote 4 should be fine too, because the number in this paragraph is surrounded by spaces. It also should work fine even though its reference appears out of order.

2 There is no footnote 1, so footnote 2 will raise a warning.
3 This is because the next one starts with a capital letter.
4 Though debounced by 400ms.

A Second Slide
This text will be part of a second slide -- because it's in title case and doesn't end with punctuation.
- A line will be inserted above this one.

- The blank line before and after this one will be removed.

- After this there should be a new paragraph.
Right here.
### Ordered Lists & Star Lists
1. The same should be done for ordered lists.

2. Of course.
And the same for star lists:
* One

* Two
Thank you.

`

@Component({
    props: {        
    },
    components: {UnitSlideViewer}
})
export default class UnitEditor extends Vue {    
    undoes:string[] = []
    previewSlideInd = -1
    viewMode = 2
    source = window.localStorage.getItem("autoSaveText") || defaultText
    autoSaveIntervalHandler = window.setInterval(()=>this.autoSave(), 15000)    
    glitch: GlitchManager = null as any as GlitchManager
        
    enableTikunim = false
    get tikunim() {
        const fixer = new Tikunim(), doc = new TikunimDoc(this.renderedSource)
        const issues = [
            ...fixer.step_trimLines(doc), 
            ...fixer.step_deleteGlop(doc), 
            ...fixer.step_fixTrailing(doc), 
            ...fixer.step_fixHeaders(doc),
            ...fixer.step_identifyFootnotes(doc).map(x=>x.diag)
        ].sort(makeComparer(x=>x.span.start))
        
        // const issuesMap = new Map<string, TikunimDiag[]>()
        // for (const i of issues) {
        //     if (!issuesMap.has(i.key)) issuesMap.set(i.key, [])
        //     issuesMap.get(i.key).push(i)
        // }
        const issuesMap = {} as Record<string, {key: string, items: TikunimDiag[]}>
        for (const i of issues) {
            if (!issuesMap[i.key]) issuesMap[i.key] = {key: i.key, items: []}
            issuesMap[i.key].items.push(i)
        }
        const issuesGrouped = Object.keys(issuesMap).map(k=>issuesMap[k])

        return { doc, issues, issuesMap, issuesGrouped }
    }
    get tikunimLines() {
        const ret = this.tikunim.doc.lines.map((line,ind) => ({line, issues: this.tikunim.issues.filter(f => f.span.lineNum === ind)}))
        // console.log(this.tikunim.issues, ret.map(l=>l.issues.length), ret)
        return ret
    }
    tikunimFix(diag: TikunimDiag, action: OurAction) {
        this.replaceText(this.tikunim.doc.fix([{diag, action}]))
    }
    tikunimFixAll(issueKey: string) {
        this.replaceText(this.tikunim.doc.fix(this.tikunim.issues.filter(i => i.key === issueKey && !!i.actions.find(a => a.default)).map(i => ({diag: i, action: i.actions.find(a => a.default)!}))))
    }
    tikunimHighlightedLineHtml(i: {line: TikunimLine, issues: TikunimDiag[]}) {
        // If >1 issue per line, show only the short ones, or the first long one if all long, so we don't mess up the HTML
        const shortIssues = i.issues.filter(x=>x.span.length<=6)
        const issuesToShow = i.issues.length <= 1 ? i.issues.slice() // 0 or 1 issues: show them
                                : shortIssues.length ? shortIssues   // 2+ issues: show any short ones
                                : [i.issues[0]]                      // all long: show the first
        issuesToShow.sort(makeComparer(i=>-i.span.start))
        let text = i.line.text
        for (const iss of issuesToShow) {
            const posOnLine = iss.span.start - i.line.span.start
            const highlight = (txt: string) => `<span style='border: 1px solid #CCC; background: ${iss.color}'>${txt}</span>`
            const replacement = iss.key === "Trailing" ? "<span style='border: 1px solid #CCC; background: #99F; font-size: 1.3em'>↖</span>" : highlight(iss.span.text)
            text = text.substr(0, posOnLine) + replacement + text.substr(posOnLine+iss.span.length)
        }
        return text
    }
    
    parsedUnit:HUnit|null = null
    renderDiags:Diag[] = []    
    mutationDiags:Diag[] = []    
    get shownDiags() { return this.renderDiags.concat(this.mutationDiags) }
    debouncedReRender = debounce(() => this.reRender(), 400)
    printPreview = false
    printPreview_formatterOptions = { footnotesAtBottom: false, hideAnnotations: false, hideComments: false }
    printPreview_withPageBreaks = false
    printPreview_withColumns = true
    printPreview_withNarrowLineSpacing = true
    printPreview_fontSize = '1em'
    //printPreview_bannerImageUrl = "/assets/art/header-purim.png"
    // printPreview_bannerHtml = `<img src="/assets/art/header-purim-new-color.png" class="img-responsive" style="width:100%">
    //     <br/>
    //     <div class="endMessage" style="text-align:center; padding: 2px">Written according to Ashkenazi psak &nbsp; &bull; &nbsp; Sponsored לע“נ ר' יחיאל בן ר' אברהם ואשתו פערל בת ר' אברהם משה &nbsp; &bull; &nbsp; Halachos reviewed by R’ Tzvi Hyman שליט“א</div>`
    printPreview_bannerHtml = `<h3>{{TITLE}} {{WARNING}}</h3>`        
    printPreview_endMessage = `<div class="endMessage">For comments, inquiries, or to request additional copies, please email <a href="#" style="text-decoration: underline">info@halacha.academy</a> or leave a message at 845-366-6667. For more great Halacha summaries and/or to subscribe, visit <a href="#" style="text-decoration: underline">halacha.academy</a></div>`

    //Mechanism to skip slides
    printPreview_slideTitleFilter = ""
    printPreview_shouldShowSlide(slideInd: number) {
        if (!this.printPreview_slideTitleFilter) return true
        const getSlide = () => this.parsedUnit!.slides![slideInd]
        const contains = (bigString:string, substr: string) => bigString.toLowerCase().indexOf(substr.toLowerCase()) !== -1
        const checkRule = (x:string) =>
                    x === String(slideInd+1) ||
                    contains(getSlide().title, x)
        const cleanedRuleList = this.printPreview_slideTitleFilter.split(",").map(x=>x.trim()).filter(x=>x)
        const rules = {
            exclude: cleanedRuleList.filter(x=>x.substr(0,1)==="!").map(x=>x.substr(1)),
            include: cleanedRuleList.filter(x=>x.substr(0,1)!=="!")
        }
        return rules.include.some(checkRule) || 
            (rules.exclude.length && !rules.exclude.some(checkRule))
    }


    onInput() {
        this.debouncedReRender()
    }
    created() {
        this.$set(this, 'glitch', new GlitchManager(this))
    }    
    mounted() {
        this.showHideSiteHeader(false)
        this.reRender()
    }
    destroyed() { this.showHideSiteHeader(true) }
    showHideSiteHeader(visibility: boolean) {
        document!.getElementById("topNav")!.style.display = visibility ? "" : "none"
        document!.body.style.overflow = visibility ? "" : "hidden"
    }
    renderedSource = "" // this added so we can computed-property off of it, and they will only recompute on rerender
    get parsedUnitIsHebrew() { 
        if (!this.parsedUnit) return false
        const isCharHeb = (char: string) => char >= "א" && char <= "ת"
        const chars = this.parsedUnit.title.split("")
        return chars.filter(isCharHeb).length > (chars.length / 2)
    }
    reRender() {
        try{
            this.renderedSource = this.source
            const converted = new UnitConverter().convert(this.source)        
            // converted.checkedOver = true             //why was this done? Ah, because we used to have a real UnitViewer up there, and it showed a warning. Now we use UnitSlideViewers
            this.parsedUnit = {author: "None", code: "none", ...converted}
            if(this.previewSlideInd==-1) this.previewSlideInd=0
            this.renderDiags = []
        } catch (ex) {
            this.parsedUnit = null
            this.renderDiags = [new Diag("Render error: " + String(ex), ex)]            
        }        
        this.glitch.autoSaver.tick(this.source)
    }
    doFootnote() {
        const ta = this.$refs.textarea as HTMLTextAreaElement, txt = this.source, ss = ta.selectionStart, se = ta.selectionEnd
        const newTxt = txt.substr(0, ta.selectionStart) + "{^" + txt.substring(ta.selectionStart, ta.selectionEnd) + "}" + txt.substr(ta.selectionEnd)
        //this.source = newTxt
        this.replaceText(newTxt)
        Vue.nextTick(()=>{            
            ta.setSelectionRange(ss+2, se+2)
            ta.focus()
        })                
    }
    autoSave(){
        //Save an undo
        if(this.source != this.undoes[this.undoes.length-1]) {
            this.undoes.push(this.source)
        }
        
        //Prune undoes
        const maxUndoes = 10
        if(this.undoes.length>maxUndoes) 
            this.undoes = this.undoes.slice(this.undoes.length - maxUndoes, maxUndoes)

        //Save to localStorage
        window.localStorage.setItem('autoSaveText', this.source)

        // this.glitch.autoSave()
    }
    get compositeSlide() {
        const ret = new HSlide()
        ret.title = '' //this.parsedUnit.title
        const slides = (this.parsedUnit && this.parsedUnit.slides) || []        
        ret.body = slides
            .filter((_,ind) => this.printPreview_shouldShowSlide(ind))
            .map(s => `# ${s.title}\n\n${s.body}\n`).join('')
        ret.body += this.printPreview_endMessage
        return ret
    }
    get curSlide() { return this.parsedUnit && this.parsedUnit.slides && this.parsedUnit.slides[this.previewSlideInd] }
    htmlEscape(str: string) {
        return String(str)
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }
    scrollToSelectedSlide() {
        Vue.nextTick(()=>{ //Otherwise curSlide isn't updated yet
            if (!this.curSlide) return;
            const lookingFor = "# " + this.curSlide.title
            const pos = this.source.indexOf(lookingFor)
            if (pos>=0) {
                const ta = this.$refs.textarea as HTMLTextAreaElement
                ta.setSelectionRange(pos, pos)
                // Focus the text area (so the scroll position moves), then give ourselves back the focus
                ta.focus()
                setTimeout(()=>(this.$refs.slideSelector as HTMLInputElement).focus(), 0)
            }
        })        
    }

    fixParagraphs() {        
        const fixer = new FileFixer(this.source)                
        this.replaceText(fixer.fixTrailingLines().fixBullets().detectAndAddHeaders().properParagraphs().text)
    }
    resolveFootnotes() {
        const collect = new FileFixer(this.source).collectFootnotes()
        if (!collect.footnotes.length) {
            alert("No footnotes were found.")
            this.mutationDiags = []
        } else {
            const res = collect.resolveFootnotes() //footnotesBothSteps(this.source)
            if(res.diags.some(d=>d.isErr)) {
                // just show the errors...                
            } else {
                this.replaceText(res.result)
            }            
            this.mutationDiags = res.diags            
        }    
            
    }
    acceptOption(option:RegExpExecArray,err:Diag) {
        const token = option[0]
        const ret = this.source.substr(0, option.index) + 
                `[${token}]` + this.source.substr(option.index+token.length)

        this.replaceText(ret)

        //Remove the error
        //this.shownDiags= this.shownDiags.filter(x=>x!=err)

        //Re-run
        this.resolveFootnotes()
    }
    replaceText(newText:string) {
        // console.log("Calling replaceText with", newText)
        this.undoes.push(this.source)
        this.source = newText
        this.reRender()
    }
    undo() {
        if(!this.undoes.length) return;
        this.source = this.undoes.pop()!
        this.reRender()
    }    
}



interface Footnote {num:number;text:string; possibilities?:RegExpExecArray[]}

class Diag {
    isErr = true
    message = "Unknown error"
    data?: any    
    constructor(message:string,data:any=undefined, isErr=true){
        this.message=message; this.data=data; this.isErr=isErr
    }
}

export class FileFixer {    
    lines:FileFixerLine[]
    constructor(input:string|FileFixerLine[]) {
        if (typeof input=="string") 
            this.lines = input.split("\n").map(ln => new FileFixerLine(ln))
        else
            this.lines = input
    }    
    /*  Procedure:
        - Fix trailing lines
        - Create paragraph breaks properly
        - Collect footnotes
        - Resolve footnots        
    */
    get text() { return this.lines.map(l=>l.text).join("\n") }
    mapEx(callback:(line:FileFixerLine, lookAhead:FileFixerLine|undefined, lookBehind:FileFixerLine|undefined)=>(FileFixerLine|string|(FileFixerLine|string)[])) {
       const ret:FileFixerLine[] = []
       for (var i = 0; i<this.lines.length; i++) {
            const whatToDoForThisLine = callback(this.lines[i], this.lines[i+1], this.lines[i-1])
            const handleSingleEl = (el:FileFixerLine|string) => ret.push(el instanceof FileFixerLine ? el : new FileFixerLine(el))            
            if(Array.isArray(whatToDoForThisLine)) 
                whatToDoForThisLine.forEach(w=>handleSingleEl(w))
            else
                handleSingleEl(whatToDoForThisLine)            
       }
       return new FileFixer(ret)
    }
    fixBullets() {
        return new FileFixer(this.text.replace(/• */g,"- "))
        //return new FileFixer(this.text.replace(/^• */mg,"- "))
    }
    fixTrailingLines() {
       const ret:FileFixerLine[] = []
       var lastLine:FileFixerLine|null = null
       for(const ln of this.lines) {
            //const lastLine = ret[ret.length - 1]
            if(lastLine && !lastLine.looksLikeBlankLine && ln.looksLikeTrailing)
                lastLine.text += " " + ln.trimmed
            else if (lastLine && /^[0-9]+$/.test(lastLine.trimmed)) //&& new FileFixerLine(lastLine.text + " " + ln.trimmed).parseFootnoteStart)
                // Last line looks like a footnote that we're the end of
                lastLine.text += " " + ln.trimmed
            else  {
                ret.push(ln)
                if(!ln.looksLikeBlankLine) lastLine = ln
            }
       }
       return new FileFixer(ret)
    }
    collectFootnotes() {        
        const footnotes:Footnote[] = []
        // Formerly we did this with a simple map. BUT I want to support "3\nFootnote here" without relying on fixParagraphs.
        // const text = this.mapEx(cur => 
        //     cur.parseFootnoteStart 
        //     ? (footnotes.push(cur.parseFootnoteStart), " ".repeat(cur.text.length)) 
        //     : cur)      
        const restOfLines:FileFixerLine[] = []
        var lastLineWasFootnote = false
        for(const cur of this.lines) {
            const checkForFootnote = cur.parseFootnoteStart
            const lastFootnote = footnotes[footnotes.length - 1]
            const pushAsIs = () => restOfLines.push(cur)
            const pushBlank = () => restOfLines.push(new FileFixerLine((" " as any).repeat(cur.text.length))) //Blankify line (to keep same length)
            if (lastLineWasFootnote && (cur.looksLikeTrailing || lastFootnote.text.trim()=="")) {
                lastFootnote.text += " " + cur.text.trim()
                lastFootnote.text = lastFootnote.text.trim()
                pushBlank()
            } else if (checkForFootnote) {
                lastLineWasFootnote = true
                footnotes.push(checkForFootnote)
                pushBlank()            
            } else {
                pushAsIs()
                lastLineWasFootnote = false
            }                
        }
        const text = restOfLines.map(l=>l.text).join("\n")
         
        const resolveFootnotes = () => {            
            const diags:Diag[] = []
            // Get all the text and prepare to search it
            var allText = text
            const execToArray = (regexp:RegExp)=>{
                var ret:RegExpExecArray[]=[], match:RegExpExecArray|null
                while((match = regexp.exec(allText)) != null) {                
                    (match as any).textAround = allText.substr(match.index - 20, 40)
                    ret.push(match)
                }
                return ret
            }   
            var lastFootnotenum = 0                   
            for(const f of footnotes) {
                const raiseErr = (message:string, isErr = true) => diags.push(new Diag(`Footnote #${f.num}: ${message}`, f, isErr))
                // Look for [2]
                f.possibilities = execToArray(new RegExp(`\\[${f.num}\\]`, "g"))
                // Wherever that doesn't exist, look for plain numbers -- preferably with no space
                const useLookbehind = !!(window as any).chrome // Firefox doesn't support it. I think all other browsers have window.chrome actually :)
                    // However, this makes it quite useless as it will detect 51 for 1, etc. Should solve this...
                const lookBehind = (spaceToo: boolean) => useLookbehind ? `(?<![0-9\\:\\@\\-${spaceToo?" ":""}]|hapter |aragraph |age )` : ""
                const lookAhead = `(?![0-9-\\:\\.])`
                if (!f.possibilities.length) f.possibilities = execToArray(new RegExp(`${lookBehind(true)}${f.num}${lookAhead}`, "g"))
                if (!f.possibilities.length) f.possibilities = execToArray(new RegExp(`${lookBehind(false)}${f.num}${lookAhead}`, "g"))
                               
                // Check for non-sequential footnotes
                if(f.num != lastFootnotenum + 1) raiseErr("Not sequential -- comes after " + lastFootnotenum, false)
                lastFootnotenum = f.num

                // Check that only one was found
                if(!f.possibilities.length)
                    raiseErr("Reference not found")
                else if (f.possibilities.length > 1)
                    raiseErr("Multiple possible references")
            }
            // Done, replace and return if no errors!
            if(!diags.some(d=>d.isErr)) {
                // We want to go through the footnotes in reverse order of reference appearance, so that the indexes still refer to the right spot after we replace
                const orderedFootnotes = footnotes.slice().sort(makeComparer(x=>-x.possibilities![0].index))
                for(const f of orderedFootnotes) {
                    const found = f.possibilities![0] //At this point the 'possibilities' array definitely exists, and there's a first one too
                    const foundAt = found.index // + offset
                    const subWith = `{^${f.text}}` 
                    allText = allText.substr(0, foundAt) + subWith + allText.substr(foundAt + found[0].length)   
                    //offset += (subWith.length - found[0].length)
                }   
                // Remove @@ signs that are meant to escape numbers
                allText = allText.replace(/\@\@/g, "")                
                // Fix incorrect placement of footnote references
                allText = allText.replace(/\ {\^/g, "{^")  // remove space before footnote
                allText = allText.replace(/(\{\^[^\}]+\})([\.\,\;])/g, (whole,footnote,commaOrPeriod) => commaOrPeriod + footnote) // footnote followed by period/comma, put after the period/comma
            }
            return {result: allText, diags}
        }

        return {footnotes, text, resolveFootnotes}
    }
    properParagraphs() {
        const createBreaks = this.mapEx(ln=>
            ln.needsParagraphBreak 
            ? ["", ln, ""] 
            : ln.looksLikeBlankLine 
            ? [] 
            : ln)
        var lastLineWasSubstance = false
        const removeExtraBreaks = createBreaks.mapEx(ln=> {
            if (ln.looksLikeBlankLine && !lastLineWasSubstance) return []
            lastLineWasSubstance = !ln.looksLikeBlankLine
            return ln
        })
        return removeExtraBreaks
    }
    detectAndAddHeaders() {
        return this.mapEx(ln=> ln.looksLikeUnannouncedHeader ? "# " + ln.trimmed : ln)
    }
}
export class FileFixerLine {
    text:string
    constructor(text:string) {
        this.text = text
    }
    get trimmed() { return this.text.trim() }
    get looksLikeTrailing() { return this.trimmed[0] && this.trimmed[0] >= "a" && this.trimmed[0] <= "z" }
    get looksLikeUnannouncedHeader() { 
        // Doesn't end with punctuation
        if ((/[\.\,\!\?]$/).test(this.trimmed)) return false
        // Starts with a Hebrew or capital letter (this basically filters out headers, lists, etc.)
        if (!(/[A-Zא-ת]/).test(this.trimmed[0])) return false
        // Every word >3 chars must begin with a Hebrew or capital letter
        const words = this.trimmed.split(" ").filter(w=>w.length>=4)        
        return words.length && words.every(w=>/^[A-Zא-ת]/.test(w)) 
    }
    get looksLikeRealHeader() { return this.trimmed[0] == "#" }
    get looksLikeListItem() {
        return /^(\*|\-|[0-9]+[\)\.]).*/.test(this.trimmed)
    }
    get looksLikeBlankLine() { return this.trimmed == "" || this.trimmed == "________________" /* which comes up in R' Shmuel's material */ }
    get parseFootnoteStart() { 
        // Support 'just a number' (i.e. no text) too. I think because it often comes out like this in e.g. Halachically Speaking and the actual footnote text is on the next line.
        if(this.trimmed.match(/^[0-9]+ *$/)) return {num: Number(this.trimmed), text: "" }         
        const allowOLstyle = true
        const regExp = allowOLstyle ? /^\[?([0-9]+)(\]| +|\. |\] +)(.+)/ : /^\[?([0-9]+)(\]| +|\] +)(.+)/
        const check = this.trimmed.match(regExp)
        return check ? {num: Number(check[1]), text: check[3].trim() } : null
    }
    get needsParagraphBreak() { return !(this.looksLikeTrailing || this.looksLikeListItem || this.looksLikeBlankLine || this.parseFootnoteStart) }
}


// New file fixer: Tikunim
type OurAction = {key: string, replacement: string, default: boolean}
// type OurFootnote = {footnoteNum: number, footnoteText: string, err: string}
class TikunimDiag {
    constructor(public span: TikunimSpan, public key: string, public level = 0, public actions: OurAction[] = []) {}
    get color() { return ['#DDD', '#FF9', '#FAA'][this.level] }
}
class TikunimDoc {
    lines: TikunimLine[]
    constructor(public entire: string) {
        let start = 0
        this.lines = entire.split("\n").map((text, lineNum) => {
            const ret = new TikunimLine(new TikunimSpan(this, start, text.length), this)
            start += text.length + 1
            return ret
        })
    }
    fix(diags: {diag: TikunimDiag, action: OurAction}[]): string {
        // Let's do this in reverse order so as not to have to offset
        let text = this.entire 
        diags = diags.slice().sort(makeComparer(x => -x.diag.span.start))
        for (const i of diags) {
            text = text.substr(0, i.diag.span.start) + i.action.replacement + text.substr(i.diag.span.start + i.diag.span.length)
        }
        return text
    }
    static toHtml(text: string) { return text.replace(/\</g, "&lt;").replace(/\n/g, "<br>") }
}
class TikunimLine {
    constructor(public span: TikunimSpan, public parent: TikunimDoc) {}
    get text() { return this.span.text }
}
class TikunimSpan {
    constructor(public parent: TikunimDoc, public start: number, public length: number) {}
    get lineNum() {
        let curPos = 0, found = false, foundNum = -1
        // console.log("lineNum for span",this.start,this.end,this.parent.entire.length, this.parent.lines.length)
        for (let i = this.parent.lines.length - 1; i >= 0; i--) {
            const l = this.parent.lines[i]
            // console.log("Checking",i,l.span.start,l.span.end)
            // if (l.span.start <= this.start && l.span.end >= this.end) return i
            if (l.span.start <= (this.start+1)) return i // +1 is because there is the linebreak before us which is also considered our line. This for trailing line diags, since they have to remove the linebreak before them. Bad idea though. TODO
        }
        throw `Line not found for span starting ${this.start} in source ending in ${this.parent.entire.length}`
    }
    get line() { 
        return this.parent.lines[this.lineNum]
    }
    get end() { return this.start + this.length }
    get text() { return this.parent.entire.substr(this.start, this.length) }
}
function regExpAll(text: string, regExp: RegExp) {
    let ret: RegExpExecArray[] = [], match: RegExpExecArray|null
    while((match = regExp.exec(text)) != null) {                
        (match as any).textAround = text.substr(match.index - 20, 40)
        ret.push(match)
    }
    return ret
}
export class Tikunim {    
    footnotesCanBeSplitAcrossLines = false
    patternsToDelete = `
        	:BY SPONSORED אין לו להקב"ה בעולמו אלא ד' אמות של הלכה בלבד... )ברכות ח.(
            :BY SPONSORED כל השונה הלכות בכל יום מובטח לו שהוא בן עולם הבא... )נדה עג.(
        	=[0-9]+ \\| HAlAchicAllY SpeAKiNg
            =Tefillah When Davening Alone \\| [0-9]+
            Test glop
            =[0-9]+ Number glop [0-9]+
    `    
    static flatten<T>(arr: T[][]) {
        const ret: T[] = []
        for (const i of arr) ret.push(...i)
        return ret
    }
    step_deleteGlop(doc: TikunimDoc): TikunimDiag[] {
        const escapeRegExp = (text: string) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        const patterns = this.patternsToDelete.split("\n").map(x=>x.trim()).filter(x=>x).map(x=>new RegExp(x[0]==="=" ? x.substr(1) : escapeRegExp(x), "g"))        
        const matches = Tikunim.flatten(patterns.map(x => regExpAll(doc.entire, x)))
        return matches.map(occ => new TikunimDiag(new TikunimSpan(doc, occ.index, occ[0].length), "Glop", 2, [{key: "Remove", default: true, replacement: ""}]))
    }
    step_fixTrailing(doc: TikunimDoc): TikunimDiag[] {
        const lineFootnotes = doc.lines.map(line => this._parseFootnote(line.text))
        let footnoteOfLastNonTrailingLine = lineFootnotes[0]
        return Tikunim._mapMaybe(doc.lines, (line,ind) => {
            const text = line.text
            const isOrphan = !(doc.lines[ind-1] && !!doc.lines[ind-1]!.text)
            const createDiag = () => new TikunimDiag(
                new TikunimSpan(doc, line.span.start-1, 1), isOrphan ? "OrphanTrailing" : "Trailing", isOrphan ? 2 : 1, 
                    [{key: "Join up", default: true, replacement: " "}])
            if (text[0]>="a" && text[0]<="z") return createDiag()
            // Also if we're a non-footnote and non-blank following a footnote, we're trailing
            const ourFootnote = lineFootnotes[ind]
            const ourFootnoteNumIsReasonable = () => ourFootnote!.num > footnoteOfLastNonTrailingLine!.num && ourFootnote!.num < footnoteOfLastNonTrailingLine!.num + 5
            const thereAreFootnotesAhead = () => [1,2,3,4].some(ofs => !!lineFootnotes[ind+ofs])
            if (footnoteOfLastNonTrailingLine && text && (!ourFootnote || (!ourFootnoteNumIsReasonable && thereAreFootnotesAhead))) return createDiag()
            // Otherwise we're not trailing, so remember our footnote for next line
            footnoteOfLastNonTrailingLine = lineFootnotes[ind]
            return undefined
        })
    }
    step_trimLines(doc: TikunimDoc): TikunimDiag[] {        
        //return doc.lines.filter(x => x.text.trim() !== x.text).map(line => new TikunimDiag(line.span, "Trim", 1, [{key: "Trim", default: true, replacement: line.text.trim()}]))                
        return Tikunim._mapMaybe(doc.lines, line => {
            const text = line.text, trimmed = text.trim()
            if (trimmed !== text) return new TikunimDiag(line.span, "Trim", 1, [{key: "Trim", default: true, replacement: line.text.trim()}])
        })
    }
    step_fixHeaders(doc: TikunimDoc): TikunimDiag[] {
        const looksLikeHeader = (text: string) => !(text.slice(-1)==="." || text.slice(-1)===",") && text.split(" ").every(x => (!!x && x[0]>="A" && x[0]<="Z") || "in the at of for of a an / & with and on".split(" ").some(q =>q===x))
        return doc.lines.filter(line => looksLikeHeader(line.text)).map(line => new TikunimDiag(line.span, "Header", 1, [
                {key: "H1", default: false, replacement: "# " + line.text},
                {key: "H3", default: true, replacement: "### " + line.text}
            ]))
    }
    step_identifyFootnotes(doc: TikunimDoc) {
        type OurFootnote = {footnoteNum: number, footnoteText: string, err: string}
        type OurFootnoteAndDiag = {diag: TikunimDiag, footnote: OurFootnote}
        const ret = [] as OurFootnoteAndDiag[]
        let curFootnote: OurFootnoteAndDiag|null = null
        doc.lines.forEach(line => {
            const curLineLooksLikeFootnote = this._parseFootnote(line.span.text)
            const numIsExpected = curLineLooksLikeFootnote && (!curFootnote || curFootnote.footnote.footnoteNum === curLineLooksLikeFootnote.num - 1)
            const previousFootnote = ret[ret.length-1]
            if (curLineLooksLikeFootnote) { 
                // We're a new footnote
                const err = previousFootnote && previousFootnote.footnote.footnoteNum !== curLineLooksLikeFootnote.num-1 ? `Non-sequential: follows ${previousFootnote.footnote.footnoteNum}` : ""
                curFootnote = { footnote: { footnoteText: curLineLooksLikeFootnote.text, footnoteNum: curLineLooksLikeFootnote.num, err }, diag: new TikunimDiag(new TikunimSpan(doc, line.span.start, curLineLooksLikeFootnote.num.toString().length), err ? "WrongFootnote" : "Footnote", err ? 1 : 0, []) }
                ret.push(curFootnote)
            } else if (curFootnote && this.footnotesCanBeSplitAcrossLines && line.span.text.trim()) { 
                // We're not a new footnote, but we should add onto previous footnote
                curFootnote.footnote.footnoteText += "\n" + line.span.text
            } else { 
                // Blank line, or text not immediately after a footnote. Stop adding text onto previous footnote
                curFootnote = null                
            }
        })
        return ret
    }
    _parseFootnote(line: string) {
        return new FileFixerLine(line).parseFootnoteStart
    }
    static _mapMaybe<T,T2>(arr: T[], maybeTransform: (item: T, ind: number)=>T2|undefined) { 
        const ret = [] as T2[]
        arr.forEach((i, ind) => { 
            const thisOne = maybeTransform(i, ind)
            if (thisOne !== undefined) ret.push(thisOne) 
        })
        return ret 
    }
}


class GlitchManager {
    status = "Ready"
    lastSaveStatus = ""

    units: string[] = []
    constructor(public ed: UnitEditor) {
        this.api<string[]>("fileDir").then((data: any) => {console.log("Units found at Glitch",data); this.units = data.data})
    }

    api<T>(cmd: string, data: object = {}) {
        const makeFormData = (obj: Record<string,any>) => Object.keys(obj).reduce((a,c) => (a.append(c,obj[c]),a) , new FormData())
        // const makeFormData2 = (obj: object) => Object.keys(obj).reduce((a,c) => c +  , "&").slice(1)
        return fetch("https://halacha-backend.glitch.me/"+cmd, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(x => x.json() as Promise<{data: T}>, err => Promise.reject("Server returned error:" + err))
    }    
    autoSaver = autoSaver<string>(() => this.save(), status => this.status = status)
    save() {
        if (!this.ed.parsedUnit) return Promise.reject("No unit")
        if (this.ed.parsedUnit.title.length<10) return Promise.reject("Specify a title")
        const headerToLookFor = "# Unit: " + this.ed.parsedUnit.title
        if (!this.ed.source.includes(headerToLookFor)) return Promise.reject(`Source doesn't match the unit title. Make sure it contains the header '${headerToLookFor}'`)

        const filename = this.ed.parsedUnit.title.replace(/[^A-Za-z0-9א-ת \-]/g, "") + ".md"
        return this.api<"OK">("fileSave", {
            filename,
            data: this.ed.source
        }).then(x => {
            if (x.data === "OK") {
                console.log("Autosaved to Glitch", x)
                return "Saved " + new Date().toTimeString().split(" ")[0] + " as " + filename
            } else throw "Response was not OK"
        })
    }
    load(filename: string) { 
        // alert(fileName)
        if (!confirm(`Really load ${filename}?\n(Make sure to save your existing unit first!)`)) return;
        this.api<string>("fileRead", {filename}).then(x => {
            this.ed.replaceText(x.data) // Which also does a re-render -- very important so that we save as the right title
        })
    }
}

function autoSaver<T>(saver: (data: T)=>Promise<string|void>, statusUpdate: (status: string)=>void) {
    var lastSavedTime: number|null = null
    var lastSavedData: T|null = null
    var currentlySaving = false
    var timeout = 0
    var specialMessage = "" // Error, or details of last save
    const savedRecently = () => lastSavedTime && ((Date.now() - lastSavedTime) < 15000)
    const setStatus = (mainStatus: string) => statusUpdate(specialMessage.includes("Error") ? specialMessage : specialMessage ? `${mainStatus} (${specialMessage})` : mainStatus)
    const autoStatus = (curData: T) => {
        // const prefix = curData.toString().length + "/" + (lastSavedData||"").toString().length + " "
        if (currentlySaving) return "Saving..."
        if (lastSavedData === curData) return "All changes saved"
        if (!lastSavedTime) return "Unsaved"
        const minsAgo = (Date.now() - lastSavedTime) / 60000
        if (minsAgo < 0.75) return "Last saved a few seconds ago"
        return `Last saved ${Math.round(minsAgo)}m ago`
    }
    const tick = (currentData: T)  => {
        // Cancel existing scheduled tick, if any
        if (timeout) { clearTimeout(timeout); timeout = 0 }
        if (currentData === lastSavedData) {
            // We're current
            setStatus(autoStatus(currentData))
        } else if (currentlySaving || savedRecently()) {
            // Schedule another save
            setStatus(autoStatus(currentData))
            timeout = setTimeout(() => tick(currentData), 2000) as any
        } else {
            currentlySaving = true
            setStatus(autoStatus(currentData))
            saver(currentData).then(optionalMsg => {
                currentlySaving = false
                lastSavedTime = Date.now()
                lastSavedData = currentData
                specialMessage = optionalMsg || ""
                setStatus(autoStatus(currentData))
            }, err => {
                currentlySaving = false
                specialMessage = "Error: " + err
                setStatus(autoStatus(currentData))
            })
        }
    }
    return { tick }
}

